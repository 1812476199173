import PropTypes from 'prop-types';
import Head from 'next/head';

// import { getImageUrl } from 'utils/imageUrl';

export default ({ ogTags, router }) => {
  const { title, description, type, image, ogImage } = ogTags;
  const ogImg = image || ogImage;

  const host = process.env.HOST;
  const fullPath = host + (router.asPath || router.pathname);

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="viewport"
        content="initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <link rel="icon" href={`/images/meta/favicon.png`}></link>
      <meta property="og:url" content={fullPath} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {ogImg?.url && <meta property="og:image" content={ogImg.url} />}
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  );
};
